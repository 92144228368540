import { DialogController } from 'aurelia-dialog';
import { inject } from 'aurelia-framework';

@inject(DialogController)
export class SurveysListTemplateDialog {

    title: string;
    bodyText: string;

    constructor(
        private dialogController: DialogController,
    ) { }

    activate(model){

        this.title = model.title;
        this.bodyText = model.template;

    }
}
