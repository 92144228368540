import { Paginated } from '@feathersjs/feathers';
import { ProgramAssetType, ProgramConfig } from '@wsb_dev/datafi-shared/lib/types';
import { autoinject } from 'aurelia-dependency-injection';
import { DatafiProAPI } from '../../../../services/api/DatafiProAPI';
import { AlertService } from '../../../../services/util/Alert';
import { ensureNumber } from '../../../../util/numbers/ensureNumber';
import { DialogService } from 'aurelia-dialog';
import { MDCDelete } from '../../../../components/mdc-delete-dialog/mdc-delete-dialog';

@autoinject
export class AssetsList {

    programId: number;
    assets: ProgramAssetType[];

    constructor(
        private api: DatafiProAPI,
        private alerts: AlertService,
        private dialogService: DialogService,
    ) {
    }

    activate(params, config) {
        this.programId = ensureNumber(config.settings.programId);
        return this.updateConfigs();
    }

    updateConfigs() {
        return this.api.programAssetTypes.find({ query: { program_id: this.programId, $sort: {title: 1} } })
            .then((result: Paginated<ProgramAssetType>) => this.assets = result.data);
    }

    delete(asset: ProgramAssetType) {

        return this.dialogService.open({
            viewModel: MDCDelete,
            model: asset.id,
        }).whenClosed((result) => {
            if (result.wasCancelled) {
                return;
            }
            this.api.programAssetTypes.remove(asset.id)
                .then(() => {
                    this.alerts.create({
                        label: 'Asset type removed',
                        dismissable: true,
                    });
                    return this.updateConfigs();
                }).catch((e) => {
                    this.alerts.create({
                        label: `Error: ${e.message}`,
                        level: 'error',
                        dismissable: true,
                    });
                });
        });

    }
}
