import { Router } from 'aurelia-router';
import { LogManager, autoinject, bindable } from 'aurelia-framework';
import { ValidationController, ValidationControllerFactory } from 'aurelia-validation';

import { ValidatedCredentials } from '../types/Credentials';

import { AlertService } from '../services/util/Alert';
import { DatafiProAPI } from '../services/api/DatafiProAPI';
import { AppConfig } from '../services/util/AppConfig';
import { FeatureFlagsService } from 'services/util/FeatureFlags';

const log = LogManager.getLogger('dfp:login');

@autoinject
export class Login {
    pending: boolean;
    controller: ValidationController;
    @bindable credentials: ValidatedCredentials;
    url = window.location.origin;

    constructor(
        private controllerFactory: ValidationControllerFactory,
        private router: Router,
        private alerts: AlertService,
        private api: DatafiProAPI,
        private environment: AppConfig,
        private flags: FeatureFlagsService,
    ) { }

    async activate() {
        if (this.api.auth.authentication) {
            return this.loginComplete();
        }
        this.credentials = new ValidatedCredentials({strategy: 'local'});
        this.credentialsChanged(this.credentials);
        await this.flags.startUnleash();
    }

    credentialsChanged(creds) {
        this.controller = this.controllerFactory.createForCurrentScope();
    }

    async login() {
        return this.controller.validate().then((result) => {
            if (!result.valid) {
                throw new Error('Form is not valid');
            }
            this.pending = true;
            return this.api.auth.login(this.credentials).then(() => this.loginComplete());
        }).catch((e) => {
            log.error(e);
            this.alerts.create({
                label: e.message,
                level: 'error',
                dismissable: true,
            });
        }).finally(() => this.pending = false);
    }

    async loginComplete() {
        this.credentials = new ValidatedCredentials({});
        this.router.navigateToRoute('home');
    }
}
