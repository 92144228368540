import { Paginated } from '@feathersjs/feathers';
import { autoinject } from 'aurelia-dependency-injection';
import { DialogController } from 'aurelia-dialog';
import { DatafiProAPI } from '../../../../services/api/DatafiProAPI';
import { AlertService } from '../../../../services/util/Alert';
import { ValidatedOrganization } from '../../../../types/Organizations';

@autoinject
export class ProgramEditOrganizations {
    orgs: ValidatedOrganization[];
    selection: ValidatedOrganization;
    confirmation: boolean;

    constructor(
        private dialogController: DialogController,
        private api: DatafiProAPI,
        private alerts: AlertService,
    ) { }

    async activate() {
        await this.api.organizations.find({query: {$limit: 50}}).then((orgs: Paginated<ValidatedOrganization>) => this.orgs = orgs.data.filter((org) => org.id !== 0));
    }

    submit() {
        if (this.selection && this.confirmation) {
            this.alerts.create({
                label: 'Organization has been activated. Save the program to persist changes',
                level: 'success',
                dismissable: true,
            });
            return this.dialogController.ok(this.selection);
        }
    }
}
