import { autoinject } from 'aurelia-framework';
import { ProgramSurvey } from '../../../types/ProgramSurvey';
import { AppConfig } from '../../../services/util/AppConfig';

export interface serviceTable  {
    title: string,
    service: string,
    id: number,
}

@autoinject
export class ShareData {
    url: string;
    programId: number;
    serviceTable: serviceTable[] = [
        {
            title: 'Projects',
            service: 'projectFeatures',
            id: 0,
        },
        {
            title: 'Project Files',
            service: 'photoFeatures',
            id: 0,
        },
    ];

    constructor(
        private config: AppConfig,
    ){}

    async activate(params, config) {
        this.programId = config.settings.programId;

        this.url = this.config?.API_HOST;

        config.settings.program.surveys.forEach((survey: ProgramSurvey) => {
            if (survey.survey_type === 'dfp-form') {
                this.serviceTable.push({
                    title: survey.title,
                    service: survey.survey_type,
                    id: survey.id,
                });
            }
        });
    }
}
