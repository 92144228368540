import { Field } from '@wsb_dev/datafi-shared/lib/types';
import { DialogController } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';
import { flattenFields } from '@wsb_dev/datafi-shared/lib/util/surveys/flattenFields';

export interface editSelection {
    column: string,
    value: boolean
}
@autoinject

export class SurveyFieldEdit {

    flatrows: Field[] = [];
    rows: Field[] = [];
    sort: number;
    selection = {
        column: undefined,
        value: undefined,
    } as editSelection;

    constructor(
        private dialogController: DialogController,
    ) { }

    activate(model: Field[]) {
        this.rows = model;
        flattenFields(model).forEach((field) => {
            this.flatrows.push(
                {
                    name: field.name,
                    label: field.label,
                    type: field.type,
                    path: field.path,
                    ...field.visibility,
                },
            );
        });
    }

    onSelectionChanged(selectAll: boolean) {
        this.flatrows.forEach((row) => {
            selectAll ? row.checked = true : row.checked = false;
        });
        this.flatrows = [...this.flatrows];
    }

    updateValue() {
        if (this.selection.value === undefined) {
            return;
        }
        this.flatrows.forEach((row) => {
            if (row.checked) {
                row[this.selection.column] = this.selection.value;
            }
        });
        this.flatrows = [...this.flatrows];
    }

    handleSort(col: string): void {
        this.sort === 1 ? this.sort = -1 : this.sort = 1;
        this.flatrows.sort(
            (a: { name: string }, b: { name: string }) => {
                const nameA = (a[col]).toString().toUpperCase();
                const nameB = (b[col]).toString().toUpperCase();

                if (this.sort === 1) {
                    // asc, arrow up
                    if (nameA < nameB) { return -1; }
                    if (nameA > nameB) { return 1; }
                    return 0;
                } else {
                    // desc, arrow down
                    if (nameA > nameB) { return -1; }
                    if (nameA < nameB) { return 1; }
                    return 0;
                }
            },
        );
    }

    submit() {
        this.applyAllEdits(this.rows, '', '');
        this.dialogController.ok(this.rows);
    }

    cancel() {
        this.removePath(this.rows);
        this.dialogController.cancel(this.rows);
    }

    removePath(rows) {
        rows.forEach((item) => {
            if (item.type === 'object') {
                delete item.path;
                this.removePath(item.fields);
            } else if (item.type === 'array') {
                delete item.path;
                this.removePath(item.fields);
            }
            delete item.path;
        });
    }

    applyAllEdits(obj: Field[], parent: string, child: string) {
        obj.forEach((item) => {
            child = item.name;
            if (item.type === 'object') {
                delete item.path;
                parent = parent.concat(item.name + '.');
                this.applyAllEdits(item.fields, parent, child);
            } else if (item.type === 'array') {
                parent = '';
                delete item.path;
                this.applyAllEdits(item.fields, parent, child);
            } else if ('name' in item) {
                const getPath = this.flatrows.find((flatrow) => flatrow.path === parent.concat(child));
                if (getPath) {
                    item.visibility.filter = getPath.filter;
                    item.visibility.list = getPath.list;
                    item.visibility.edit = getPath.edit;
                }
            }
        });
    }
}
