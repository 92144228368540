import { Field } from '@wsb_dev/datafi-shared/lib/types/Field';
import { getFields } from '@wsb_dev/datafi-shared/lib/util/fields/getFields';
import { PLATFORM, autoinject } from 'aurelia-framework';
import { ValidationController, ValidationControllerFactory } from 'aurelia-validation';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';
import { Program } from '../../../types/Program';
import { ThumbnailJobData } from '@wsb_dev/datafi-shared/lib/types/jobs/ImageJob';
import { AlertService } from '../../../services/util/Alert';
import { ProgramEditOrganizations } from './program-edit-organizations/program-edit-organizations';
import { DialogService } from 'aurelia-dialog';
import { ValidatedOrganization } from '../../../types/Organizations';
import { Paginated } from '@feathersjs/feathers';
import { generatePath } from '@wsb_dev/datafi-shared/lib/util/files/generatePath';

PLATFORM.moduleName('./program-edit-organizations/program-edit-organizations');

@autoinject
export class ProgramEdit {
    validationController: ValidationController;
    program: Program;
    sort: number;
    programId: number;
    isProcessing: boolean;
    orgName: ValidatedOrganization;

    basicFields: Field[] = getFields([
        { name: 'title' },
        { name: 'description' },
        {
            name: 'settings', type: 'object', fields: [
                {
                    name: 'tabs', type: 'object', fields: [
                        { name: 'projects', type: 'select', options: [{ value: true, label: 'Active' }, { value: false, label: 'Hidden' }] },
                        { name: 'collect', type: 'select', options: [{ value: true, label: 'Active' }, { value: false, label: 'Hidden' }] },
                        { name: 'files', type: 'select', options: [{ value: true, label: 'Active' }, { value: false, label: 'Hidden' }] },
                    ],
                },
                {
                    type: 'object',
                    name: 'userLocation',
                    label: 'User Location',
                    fields: [
                        { name: 'autoEnabled', label: 'Auto track users location', type: 'select', options: [{ value: true, label: 'Yes' }, { value: false, label: 'No' }] },
                        { name: 'source', label: 'User location source', type: 'select', options: [{ value: 'gps' }, { value: 'profile' }] },
                    ],
                },
            ],
        },
    ])

    constructor(
        private validationFactory: ValidationControllerFactory,
        private api: DatafiProAPI,
        private alerts: AlertService,
        private dialogs: DialogService,
    ) { }

    async activate(params: any, config) {
        this.program = config.settings.program;
        this.sort = -1;
        this.programId = parseInt(params.id, 10);

        if (!this.program.settings) {
            this.program.settings = {};
        }

        if (!this.program.settings?.tabs) {
            this.program.settings.tabs = {
                files: true,
                collect: true,
                projects: true,
            };
        }

        if (!this.program.fileFolders) {
            this.program.fileFolders = [
                { label: 'Documentation', value: 'docs' },
                { label: 'Images', value: 'images' },
            ];
        }

        if (!this.program.thumbnailUrl) {
            this.program.thumbnailUrl = '';
        }

        if (!this.program.settings.userLocation) {
            this.program.settings.userLocation = {
                autoEnabled: false,
                source: 'gps',
            };
        }
        if (this.program.managementOrganizationId) {
            this.api.organizations.find({query: {$limit: 50}}).then((orgs: ValidatedOrganization) => {
                this.orgName = orgs.data.find((org) => org.id === this.program.managementOrganizationId);
            });
        }

        this.validationController = this.validationFactory.createForCurrentScope();
    }

    async updateProgramThumbnail($event) {
        this.isProcessing = true;
        const fileId = $event.detail.created[0].id;
        const job = await this.api.jobs.create({
            name: 'thumbnail',
            data: {
                id: fileId,
                width: 720,
            } as ThumbnailJobData,
        }, {
            query: { $sync: true },
        });
        this.program.thumbnailUrl = job.result.id;
        this.alerts.create({
            level: 'success',
            label: 'Program thumbnail updated, please save the program to see the changes',
            dismissable: true,
        });
        this.isProcessing = false;
    }

    getProgramImageName = (f: File): string => {
        return generatePath({
            name: 'thumbnail',
            programId: this.programId,
            fileName: f.name,
        });
    }

    manageOrg() {
        return this.dialogs.open({
            viewModel: ProgramEditOrganizations,
            model: this.programId,
        }).whenClosed((result) => {
            if (result.wasCancelled) {
                return;
            }
            this.program.managementOrganizationId = result.output.id;
            this.orgName = result.output;
        });
    }
}
