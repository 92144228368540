import { autoinject, LogManager } from 'aurelia-framework';
import { FormSubmission } from '@wsb_dev/datafi-shared/lib/types/FormSubmission';
import { ProgramSurvey } from '@wsb_dev/datafi-shared/lib/types';
import { userHasPermission } from '@wsb_dev/datafi-shared/lib/util/users/userHasPermission';
import { ValidatedFormSubmission } from '../types/FormSubmission';
import { FormEventData } from '../components/mdc-dynamic-form/mdc-dynamic-form';
import { FormSubmissionDialog } from '../components/form-submission-dialog/form-submission-dialog';
import { DatafiProAPI } from '../services/api/DatafiProAPI';
import { AuthenticationService } from '../services/api/authentication';
import { AlertService } from '../services/util/Alert';
import set from 'lodash.set';
import { v4 as uuidv4 } from 'uuid';
import { ensureNumber } from '../util/numbers/ensureNumber';
import { Redirect, Router } from 'aurelia-router';
import { DialogService } from 'aurelia-dialog';

const log = LogManager.getLogger('dfp:actions');

@autoinject
export class DFPForm {
    formSubmission: FormSubmission;
    survey: ProgramSurvey;

    constructor(
        private api: DatafiProAPI,
        private alerts: AlertService,
        private auth: AuthenticationService,
        private dialogService: DialogService,
        private router: Router,
    ) { }

    async canActivate(params) {
        const hasPermission = userHasPermission(this.api.auth.me, 'api/v1/form-submissions', 'create', ensureNumber(params.programId));
        if (hasPermission) {
            const programSurvey = await this.api.programSurveys.get(params.surveyId).catch((err) => {
                log.error(`[dfp-form]: could not load survey: ${err}`);
                this.alerts.create({
                    label: 'Could not load survey',
                    level: 'warning',
                    dismissable: true,
                });
            }) as ProgramSurvey;

            const canAddDFPform = programSurvey.actions.find((action) => action.id === 'EditDFPForm');
            if (canAddDFPform) {
                programSurvey ? this.survey = programSurvey : {};
                return true;
            } else {
                return new Redirect('/');
            }
        } else {
            return new Redirect('/');
        }

    }

    async activate(params) {
        //parse params.metadata into a workable object
        const metadataObj: Record<string, unknown> = {};
        if (params.metadata) {
            const metadataKeyValues = new URLSearchParams(params.metadata).entries();
            for (const pair of metadataKeyValues) {
                metadataObj[pair[0]] = pair[1];
            }
        }

        if (metadataObj) {
            const defaults = {};
            Object.keys(metadataObj).forEach((field) => {
                if (metadataObj[field]) {
                    set(defaults, field, metadataObj[field]);
                }
            });
            this.formSubmission  = new ValidatedFormSubmission(defaults);
        }
    }

    onSubmit(form: FormEventData): Promise<void> {
        delete form.context.metadata?.__expanded;
        const query = {
            survey_id: this.survey.id,
            instance_id: uuidv4(),
            program_id: this.survey.program_id,
            metadata: form.context.metadata,
            attachments: form.context.attachments,
        };

        if (!form.isValid) {
            return this.alerts.create({
                level: 'error',
                label: `Form is invalid: ${form.constraintErrors.map((e) => {
                    return `${e.field}: ${e.message}`;
                }).join('; ')}`,
                dismissable: true,
            });
        }

        this.dialogService.open({
            viewModel: FormSubmissionDialog,
            model: { id: this.formSubmission.id, query: query },
        }).whenClosed((result) => {
            // repopulate form logic
            log.info(`[dfp-form]: form-submission status: ${result.output}`);
            if (result.output === 'success') {
                this.router.navigateToRoute('projects', { programId: this.survey.program_id });
            }
        });
    }
}
