import { Option } from '@wsb_dev/datafi-shared/lib/types';
import { autoinject } from 'aurelia-dependency-injection';
import { LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ValidationController, ValidationControllerFactory } from 'aurelia-validation';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';
import { AlertService } from '../../../services/util/Alert';
import { DirtyChecker } from '../../../types/DirtyChecker';
import { ValidatedWebhook } from '../../../types/Webhooks';
import { ensureNumber } from '../../../util/numbers/ensureNumber';

const log = LogManager.getLogger('dfp:admin');

@autoinject
export class WebhookEdit extends DirtyChecker {
    public dirtyProps: string[] = ['webhook'];
    webhook: ValidatedWebhook;
    programId: number;
    webhookId: number;
    validation: ValidationController;
    active = 'headers';
    triggerOptions: Option[] = [
        {value: 'create', label: 'Item Created'},
        // {value: 'patch', label: 'Item Updated'}, //This function may be added later, but does not work as of now.
    ]

    constructor(
        private api: DatafiProAPI,
        private _validation: ValidationControllerFactory,
        private alerts: AlertService,
        private router: Router,
    ){ super(); }

    activate(params, config): void {
        this.programId = ensureNumber( config.settings.programId );
        this.webhookId = ensureNumber( params.webhookId );
        this.initWebhook();
        this.validation = this._validation.createForCurrentScope();
    }

    async initWebhook(){
        const defaults = {
            program_id: this.programId,
            headers: {},
            data: {},
            triggers: [],
            enabled: true,
        };
        if(this.webhookId){
            await this.api.programWebhooks.get(this.webhookId)
                .then((result) => {
                    this.webhook = new ValidatedWebhook({
                        ...defaults,
                        ...result,
                    });
                });
        } else {
            this.webhook = new ValidatedWebhook(defaults);
        }

        this.updateClones();
    }

    createTrigger(){
        if(!this.webhook.triggers){
            this.webhook.triggers = [];
        }
        this.webhook.triggers.push({
            label: '',
            service: 'api/v1/form-submissions',
            methods: [],
        });
    }

    removeTrigger(index: number){
        this.webhook.triggers.splice(index, 1);
    }

    cancel() {
        this.router.navigateToRoute('webhook-list');
    }

    submit(webhook: ValidatedWebhook){
        this.validation.validate()
            .then((result) => {
                if(!result.valid){
                    throw new Error('Form is not valid');
                }

                return webhook.id ?
                    this.api.programWebhooks.patch(webhook.id, webhook) :
                    this.api.programWebhooks.create(webhook);
            })
            .then((result) => {
                this.alerts.create({
                    level: 'success',
                    label: webhook.id ? 'Webhook updated' : 'Webhook created',
                    dismissable: true,
                });

                Object.assign(this.webhook, result);
                this.updateClones();

                this.router.navigateToRoute('webhook-list');
            })
            .catch((e) => {
                log.error(e);
                this.alerts.create({
                    level: 'error',
                    label: e.message,
                    dismissable: true,
                });
            });
    }
}
