import { Paginated } from '@feathersjs/feathers';
import { Job } from '@wsb_dev/datafi-shared/lib/types';
import { autoinject } from 'aurelia-dependency-injection';
import { Router } from 'aurelia-router';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';

@autoinject
export class WebhookDetail {
    webhookId: number;
    programId: number;
    jobs: Job<any>[];
    loading: boolean;

    constructor(private api: DatafiProAPI, private router: Router) {}

    activate(params, config) {
        this.webhookId = params.webhookId;
        this.programId = config.settings.programId;
    }

    attached() {
        this.updateJobs();
    }

    updateJobs() {
        this.loading = true;
        this.api.jobs
            .find({
                query: {
                    $limit: 100,
                    $sort: { id: -1 },
                    webhook_id: this.webhookId,
                    program_id: this.programId,
                },
            })
            .then((result: Paginated<Job<any>>) => {
                this.jobs = result.data;
            }).finally(() => {
                this.loading = false;
            });
    }

    async retryJob(job: Job) {
        const data = this.jobs.find((j) => j.id === job.id);
        const jobWithData = await this.api.jobs.get(data.id, {query: {$select: ['data']}});

        return this.api.jobs
            .create({
                ...data,
                data: {
                    ...jobWithData.data,
                    retryId: job.id,
                    retryAt: new Date().toISOString(),
                    retryNote: `Retry of job ${job.id} from webhook ${this.webhookId} on program ${this.programId}.`,
                },
            })
            .then(() => this.updateJobs());
    }

    removeJob(job: Job) {
        // not implemented on backend
        return this.api.jobs.remove(job.id, {})
            .finally(() => this.updateJobs());
    }
}
