import { AssetGeometryType, geometryTypes } from '@wsb_dev/datafi-shared/lib/types/ProgramAsset';
import { ProgramAssetType } from '@wsb_dev/datafi-shared/lib/types/ProgramAssetType';
import { autoinject } from 'aurelia-dependency-injection';
import { Router } from 'aurelia-router';
import { ValidationController, ValidationControllerFactory } from 'aurelia-validation';
import { DatafiProAPI } from '../../../../services/api/DatafiProAPI';
import { AlertService } from '../../../../services/util/Alert';
import { ValidatedProgramAssetType } from '../../../../types/ProgramAssetType';
import { ensureNumber } from '../../../../util/numbers/ensureNumber';

@autoinject
export class AssetTypeEdit {
    assetTypes: ProgramAssetType[];
    validationController: ValidationController;
    assetType: ValidatedProgramAssetType;
    assetId: number;
    programId: number;

    geometryTypes: AssetGeometryType[] = geometryTypes;

    constructor(
        private api: DatafiProAPI,
        private validationControllerFactory: ValidationControllerFactory,
        private alertService: AlertService,
        private router: Router,
    ) { }

    async activate(params, config) {
        this.assetId = ensureNumber(params.assetId);
        this.programId = ensureNumber(config.settings.programId);

        const asset = this.assetId ? await this.api.programAssetTypes.get(this.assetId, {query: {program_id: this.programId}}) : {program_id: this.programId};
        this.assetType = new ValidatedProgramAssetType(asset);
        this.geometryTypes = geometryTypes;
        this.validationController = this.validationControllerFactory.createForCurrentScope();
    }

    submit(finish?: boolean) {
        const assetType = this.assetType;
        return this.validationController.validate()
            .then((result) => {
                if (result.valid) {
                    if (assetType.id) {
                        return this.api.programAssetTypes.patch(assetType.id, assetType);
                    } else {
                        return this.api.programAssetTypes.create(assetType);
                    }
                }
            })
            .then((result) => {
                this.alertService.create({
                    label: assetType.id ? 'Asset type saved' : 'Asset type created',
                    level: 'success',
                });
                if (finish) {
                    return this.router.navigateToRoute('assets-list');
                }
                if (!assetType.id) {
                    return this.router.navigateToRoute('assets-edit', {assetId: result.id});
                }
            })
            .catch((e) => this.alertService.create({
                label: e.message,
                level: 'error',
            }));
    }

    delete() {
        return this.api.programAssetTypes.remove(this.assetType.id)
            .then(() => {
                this.alertService.create({
                    label: 'Asset Removed',
                    level: 'success',
                });
                this.router.navigateToRoute('assets-list');
            });
    }

    cancel() {
        this.router.navigateToRoute('assets-list');
    }
}
