import { Secret, ProgramSecret, Program } from '@wsb_dev/datafi-shared/lib/types';
import { autoinject, bindable } from 'aurelia-framework';
import { ProgramAuthEdit } from '../../../components/programs/program-auth-edit/program-auth-edit';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';
import { AlertService } from '../../../services/util/Alert';
import { ValidatedSecret } from '../../../types/Secret';
import { ensureNumber } from '../../../util/numbers/ensureNumber';

@autoinject
export class AuthEdit {

    ProgramAuthEdit = ProgramAuthEdit;
    ValidatedSecret = ValidatedSecret;

    @bindable secrets: Secret[] = [];
    selectedSecrets: ProgramSecret[] = [];

    @bindable programSecrets: ProgramSecret[] = [];
    programSecretIds: Set<number> = new Set();

    @bindable programId: number;
    program: Program;

    constructor(
        private api: DatafiProAPI,
        private alerts: AlertService,

    ){
    }

    async activate(params, config){
        this.programId = ensureNumber( config.settings.programId );
        this.program = await this.api.programs.get(this.programId);
    }

    secretsChanged(){
        this.updateSecretsEnabled();
    }

    programSecretsChanged(secrets: ProgramSecret[]){
        this.programSecretIds.clear();

        for(const secret of secrets){
            this.programSecretIds.add(secret.secret_id);
        }

        this.updateSecretsEnabled();
    }

    toggleSecret(secret: ValidatedSecret){
        let promise;
        if(this.programSecretIds.has(secret.id)){
            promise = this.api.programSecrets.remove(null, {query: {program_id: this.program.id, secret_id: secret.id}})
                .then(() => {
                    const itemsToRemove = this.programSecrets.filter((s) => s.secret_id === secret.id);
                    itemsToRemove.forEach((item) => {
                        const index = this.programSecrets.indexOf(item);
                        this.programSecrets.splice(index, 1);
                    });
                    this.programSecretIds.delete(secret.id);
                });
        } else {
            promise = this.api.programSecrets.create({
                secret_id: secret.id,
                program_id: this.program.id,
            }).then((result) => {
                this.programSecrets.push({...result, secret: secret});
                this.programSecretIds.add(secret.id);
            });
        }

        return promise.then(() => {

            this.alerts.create({
                label: 'Secret updated',
                dismissable: true,
            });
        });
    }

    setSecrets(rows: ValidatedSecret[]){
        this.secrets = rows;
        this.updateSecretsEnabled();

    }

    updateSecretsEnabled(){
        const rows = this.secrets;
        rows.forEach((r) => {
            if(this.programSecretIds.has(r.id)){
                r.__enabled = true;
            }
        });
    }
}
