import { APIFile } from '@wsb_dev/datafi-shared/lib/types/File';
import { DialogController } from 'aurelia-dialog';
import { autoinject, PLATFORM } from 'aurelia-framework';
import { DatafiProAPI } from '../../../../services/api/DatafiProAPI';
import { ProgramSurvey } from '../../../../types/ProgramSurvey';
import { dataUriToBlob } from '../../../../services/api/hooks/files/dataUriToBlob';
import { AlertService } from '../../../../services/util/Alert';
import { flattenFields } from '@wsb_dev/datafi-shared/lib/util/surveys/flattenFields';
import { Field } from '@wsb_dev/datafi-shared/lib/types';
import { v4 as uuidv4 } from 'uuid';
import pThrottle from 'p-throttle';

PLATFORM.moduleName('../surveys-list-add-geojson/surveys-list-add-geojson');

export interface IMapToSurveyField {
    surveyField: Field;
    geojsonField: string;
}

@autoinject
export class SurveysListAddGeojson {
    surveys: ProgramSurvey[];
    survey: ProgramSurvey;
    surveyFields: Field[];
    mappedField: IMapToSurveyField[] = [];
    geojsonData: Record<string, any>;

    pending: boolean;
    progress: string;

    constructor(
        private dialogController: DialogController,
        private api: DatafiProAPI,
        private alerts: AlertService,
    ) { }

    activate(model: { file: string, surveys: ProgramSurvey[] }) {
        this.surveys = model.surveys;
        this.getGeoJSONFields(model.file);
    }

    flattenFields() {
        this.surveyFields = flattenFields(this.survey.surveySchema);
        this.surveyFields = this.surveyFields.filter((field) => {
            const pathsToRemove = [
                'metadata.survey_id',
                'metadata.project_id',
                'metadata.username',
                'metadata.username',
                'metadata.userId',
                'metadata.email',
                'metadata.program_id',
            ];
            return field.path.includes('metadata.') // only include fields with metadata paths
                && !pathsToRemove.includes(field.path) // remove specific paths
                && field.type === 'text';// only show fields that are visible in table
        });
    }

    async getGeoJSONFields(id) {
        const result = await this.api.files.get(id, { $url: false }) as APIFile;
        const blob = dataUriToBlob(result.uri as string);
        this.geojsonData = await blob.text().then((result) => {
            const parseJSON = JSON.parse(result);

            for (let i = 0; i < parseJSON.features.length; i++) {
                parseJSON.features[i].geometry.coordinates = parseJSON.features[i].geometry.coordinates.slice(0, 2);
            }
            return parseJSON;
        }).catch((e) => {
            this.alerts.create({
                label: `Could not fetch data ${e}`,
                level: 'error',
                timeout: 8000,
                dismissable: true,
            });
        });
    }

    mapToSurveyField(selection: IMapToSurveyField) {
        const isMapped = this.mappedField?.some((field) => field.geojsonField === selection.geojsonField);

        if (!selection.surveyField) { // undefined survey field
            this.mappedField = this.mappedField?.filter((field) => field.geojsonField === selection.geojsonField);
            return;
        }

        if (isMapped) { // if a selection has already been made, replace prop value
            const field = this.mappedField.find((field) => field.geojsonField === selection.geojsonField);
            Object.assign(field, selection);
        } else { // If field selection hasn't been made, add new property & value
            this.mappedField.push(selection);
        }
    }

    async submit(): Promise<void> {
        if (!this.survey || !this.geojsonData) {
            return;
        }
        const throttle = pThrottle({
            limit: 3,
            interval: 1000,
        });

        Promise.all(
            this.geojsonData.features.map(async (f, i) => {
                this.pending = true;
                const metadataObj = this.mappedField.length ? this.buildMetadataObj(f) : { metadata: {} };
                Object.assign(metadataObj['metadata'], {
                    program_id: this.survey.program_id,
                    survey_id: this.survey.id,
                    username: this.api.auth.me.username,
                    fullname: this.api.auth.me.fullname,
                });

                const throttled = throttle(() => {
                    this.progress = (i / this.geojsonData.features.length * 100).toFixed();
                    return this.api.formSubmissions.create({
                        survey_id: this.survey.id,
                        instance_id: uuidv4(),
                        geom: f.geometry,
                        program_id: this.survey.program_id,
                        ...metadataObj,
                        attachments: [],
                    }).catch((e) => {
                        this.pending = false;
                        this.alerts.create({
                            label: `Geojson was not created: ${e}`,
                            level: 'error',
                            timeout: 8000,
                            dismissable: true,
                        });
                        return this.dialogController.ok();
                    });
                });
                await throttled();
            }),
        ).then(() => {
            this.pending = false;
            this.alerts.create({
                label: `${this.geojsonData.features.length} Records added successfully`,
                level: 'success',
                timeout: 8000,
                dismissable: true,
            });
            this.dialogController.ok();
        });
    }

    buildMetadataObj(feature) {
        const output = {};
        let current = {};
        for (const path of this.mappedField) {
            current = output;
            for (const segment of path.surveyField.path.split('.')) {
                if (!(segment in current)) {
                    if (path.surveyField.path.split('.').length - 1 === path.surveyField.path.split('.').indexOf(segment)) {
                        current[segment] = feature.properties[path.geojsonField] ? feature.properties[path.geojsonField] : undefined;
                    } else {
                        current[segment] = {};
                    }
                }
                current = current[segment];
            }
        }
        return output;
    }
}
