import { ActiveProgram } from '../services/util/ActiveProgram';
import { AlertService } from '../services/util/Alert';
import { Router } from 'aurelia-router';
import { autoinject, bindable } from 'aurelia-framework';
import { Program } from '../types/Program';
import { version } from '../../package.json';
import { debounced } from '../util/decorators/debounced';
import { subscribe } from '../util/decorators/subscribe';
import { EventAggregatorWrapper } from '../util/events/eventAggregatorWrapper';
import { AuthCreated } from '@wsb_dev/datafi-shared/lib/types/Events';
import { DatafiProAPI } from '../services/api/DatafiProAPI';
import { DB } from '../services/util/DB';
import { userHasPermission } from '@wsb_dev/datafi-shared/lib/util/users/userHasPermission';

const params = {
    $limit: 100,
};

export interface IDashboardSelect {
    id: number,
    label: string
}

@subscribe({
    events: [
        { event: AuthCreated, eventEmitter: 'ea', fn: 'handleAuthCreated' },
    ],
})
@autoinject
export class PageHome {
    message = 'Welcome To Datafi!';
    @bindable programs: Program[];
    @bindable params = {
        $limit: 20,
        $skip: 0,
    };
    @bindable pageNumber = 1;
    @bindable filesTotal = 0;
    @bindable perPage = 20;
    programSearch: string;
    isLoading: boolean;
    numAccess = 0;
    version = version;
    dashboardList: IDashboardSelect[] = [];

    constructor(
        private router: Router,
        private alerts: AlertService,
        private program: ActiveProgram,
        private ea: EventAggregatorWrapper,
        private api: DatafiProAPI,
        private db: DB,
    ) {

    }

    attached() {
        this.updatePrograms();
    }

    detached() {
        this.programs?.forEach((p) => p.destroy());
    }

    pageNumberChanged() {
        this.params = {
            ...this.params,
            $skip: (this.pageNumber - 1) * this.perPage,
        };

        this.updatePrograms();
    }
    perPageChanged(perpage: number): void {
        this.params = {
            ...this.params,
            $limit: perpage,
            $skip: (this.pageNumber - 1) * this.perPage,
        };
        this.updatePrograms();
    }

    programsChanged(newValue, oldValue){
        oldValue?.forEach((p) => p.destroy());
    }

    handleAuthCreated(){
        if(!this.programs && !this.isLoading){
            this.updatePrograms();
        }
    }

    @debounced(200)
    updatePrograms() {
        this.isLoading = true;
        if (!this.api.auth.me) {
            return;
        }
        this.getPrograms();
    }

    selectProgram(id: number) {
        if (id) {
            this.alerts.create({
                label: 'Loading program...',
                dismissable: true,
            });
            this.program.load(id)
                .then(() => this.router.navigateToRoute('projects', { programId: id }));
        }
    }

    editProgram(program?: Program) {
        return this.router.navigateToRoute('program/config', { id: program?.id || 0 });
    }

    @debounced(200)
    searchPrograms() {
        this.isLoading = true;

        let searchParams = null;

        if (this.programSearch.length > 1) {
            searchParams = {
                $or: [
                    { title: { $ilike: '%' + this.programSearch + '%' } },
                    { description: { $ilike: '%' + this.programSearch + '%' } },
                ],
            };
        }
        this.getPrograms(searchParams);

    }

    getDashboards(id: number) {
        this.dashboardList = [];
        const orgId = this.programs.find((p) => p.id === id)?.managementOrganizationId;
        const matchingOrg = this.api.auth.me.organizationId === orgId;
        const isAdmin = userHasPermission(this.api.auth.me, '*', '*');

        if (orgId) {
            if (!matchingOrg && !isAdmin) {
                return;
            }
        }

        this.api.programConfigs.find({
            query: {
                program_id: id,
                type: 'dashboard',
                $select: ['id', 'label'],
            },
        }).then((result: any) => {
            this.dashboardList = result.data as IDashboardSelect[];
        });
    }

    getPrograms(searchParams = null) {
        this.api.programs.find({
            query: {
                ...params,
                ...this.params,
                $eager: 'roles.role',
                $sort: {title: 1},
                ...searchParams,
            },
        }).then((result: any) => {
            this.programs = result.data.map((p) => {
                p = new Program(p, this.api.files);
                p.getThumbnail();
                return p;
            });

            this.filesTotal = result.total;
            if (this.numAccess < 1) {
                this.numAccess = this.programs.length;
            }
            this.isLoading = false;
        });
    }
}
