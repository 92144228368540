import { Paginated } from '@feathersjs/feathers';
import { ProgramConfig } from '@wsb_dev/datafi-shared/lib/types';
import { autoinject } from 'aurelia-dependency-injection';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';
import { AlertService } from '../../../services/util/Alert';
import { ensureNumber } from '../../../util/numbers/ensureNumber';
import { DialogService } from 'aurelia-dialog';
import { MDCDelete } from '../../../components/mdc-delete-dialog/mdc-delete-dialog';

@autoinject
export class ConfigsList {

    programId: number;
    configs: ProgramConfig[];

    constructor(
        private api: DatafiProAPI,
        private alerts: AlertService,
        private dialogService: DialogService,
    ) {
    }

    activate(params, config) {
        this.programId = ensureNumber(config.settings.programId);
        return this.updateConfigs();
    }

    updateConfigs() {
        return this.api.programConfigs.find({ query: { program_id: this.programId, $sort: {label: 1}, $limit: 50} })
            .then((result: Paginated<ProgramConfig>) => this.configs = result.data);
    }

    delete(config: ProgramConfig) {

        return this.dialogService.open({
            viewModel: MDCDelete,
            model: config.id,
        }).whenClosed((result) => {
            if (result.wasCancelled) {
                return;
            }
            this.api.programConfigs.remove(config.id)
                .then(() => {
                    this.alerts.create({
                        label: 'Config removed',
                        dismissable: true,
                    });
                    return this.updateConfigs();
                }).catch((e) => {
                    this.alerts.create({
                        label: `Error: ${e.message}`,
                        level: 'error',
                        dismissable: true,
                    });
                });
        });

    }
}
