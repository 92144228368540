/* eslint-disable @typescript-eslint/no-empty-function */
import { autoinject, observable } from 'aurelia-framework';
import { DatafiProAPI } from '../../services/api/DatafiProAPI';
import { ValidatedProgramConfig } from '../../types/ProgramConfig';
import { ProgramConfig } from '@wsb_dev/datafi-shared/lib/types';
import { IDashboardConfig, IDashboardItem } from '../../types/Dashboards';
import { StoreManager } from '../../services/util/StoreManager';

@autoinject
export class DashboardShareable {

    config: ProgramConfig<IDashboardConfig>;

    constructor(
        private element: Element,
        private api: DatafiProAPI,
        private stores: StoreManager,
    ) { }

    async activate(params) {
        if (params.configId){

            this.config = await(
                this.api.programConfigs.get(params.configId)
                    .then((config) => new ValidatedProgramConfig(config)));

            await this.stores.activate(params.programId);

        }
    }

}
