import { ProgramWebhook } from '@wsb_dev/datafi-shared/lib/types/ProgramWebhook';
import { Job } from '@wsb_dev/datafi-shared/lib/types';
import { LogManager, autoinject } from 'aurelia-framework';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';
import { Paginated } from '@feathersjs/feathers';
import { AlertService } from '../../../services/util/Alert';
import { DialogService } from 'aurelia-dialog';
import { MDCDelete } from '../../../components/mdc-delete-dialog/mdc-delete-dialog';

const log = LogManager.getLogger('dfp:admin');

export interface IProgramWebhookExtended extends ProgramWebhook {
    __completed: number;
    __progress: number;
    __failed: number;
    __delayed: number;
}

@autoinject
export class WebhookList {
    programId: number;
    webhooks: IProgramWebhookExtended[];
    loading: boolean;

    constructor(
        private api: DatafiProAPI,
        private alerts: AlertService,
        private dialogService: DialogService,
    ) { }

    activate(params) {
        this.programId = params.id;
    }

    attached() {
        this.updateWebhooks();
    }

    async updateWebhooks() {
        this.loading = true;
        const jobs = await this.api.jobs.find({query: {program_id: this.programId, $limit: 100, $select: ['status', 'webhook_id']}}) as Paginated<Job<any>>;
        const webhooks = await this.api.programWebhooks.find({
            query: { program_id: this.programId, $sort: {label: 1}, $limit: 100},
        })as Paginated<ProgramWebhook>;
        this.webhooks = webhooks.data.map((item) => {
            const filteredJobs = jobs.data.filter((job) => job.webhook_id === item.id);
            return {
                ...item,
                __completed: filteredJobs.filter((job) => job.status === 'completed').length || 0,
                __progress: filteredJobs.filter((job) => job.status === 'active').length || 0,
                __failed: filteredJobs.filter((job) => job.status === 'failed').length || 0,
                __delayed: filteredJobs.filter((job) => job.status === 'delayed').length || 0,
            };
        });
        this.loading = false;
    }

    deleteWebhook(webhook: ProgramWebhook): Promise<void> {

        return this.dialogService.open({
            viewModel: MDCDelete,
            model: webhook.id,
        }).whenClosed((result) => {
            if (result.wasCancelled) {
                return;
            }
            this.api.programWebhooks.remove(webhook.id)
                .then(() => {
                    this.alerts.create({
                        level: 'success',
                        label: 'Webhook Deleted',
                        dismissable: true,
                    });
                    this.updateWebhooks();
                })
                .catch((e) => {
                    log.error(e);
                    this.alerts.create({
                        level: 'error',
                        label: e.message,
                        dismissable: true,
                    });
                });
        });
    }
}
