import { Paginated } from '@feathersjs/feathers';
import { PLATFORM } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';
import { AlertService } from '../../../services/util/Alert';
import { ProgramSurvey } from '../../../types/ProgramSurvey';
import { ensureNumber } from '../../../util/numbers/ensureNumber';
import { APIFile, Job } from '@wsb_dev/datafi-shared/lib/types';
import { ActionService } from '../../../services/actions/ActionService';
import { SaveFileActionData } from '@wsb_dev/datafi-shared/lib/types';
import { createCustomEvent } from '../../../util/events/createCustomEvent';
import { getReportTemplate } from '@wsb_dev/datafi-shared/lib/util/reports/getReportTemplate';
import { IXlsFormJobData } from '@wsb_dev/datafi-shared/lib/types/jobs/XlsFormData';
import { getJobResult } from '../../../services/api/util/jobs/getJobresult';
import { DialogService } from 'aurelia-dialog';
import { SurveysListTemplateDialog } from './surveys-list-template-dialog/surveys-list-template-dialog';
import { MDCDelete } from '../../../components/mdc-delete-dialog/mdc-delete-dialog';
import { debounced } from '../../../util/decorators/debounced';
import { IServiceType, generatePath } from '@wsb_dev/datafi-shared/lib/util/files/generatePath';
import { SurveysListAddGeojson } from './surveys-list-add-geojson/surveys-list-add-geojson';

PLATFORM.moduleName('./surveys-list-template-dialog/surveys-list-template-dialog');

export interface IUploadEventDetail {
    created: APIFile[];
    removed: APIFile[];
}

@autoinject
export class SurveysList {
    programId: number;
    surveys: ProgramSurvey[];
    files: APIFile[];
    hideFiles: boolean;
    jobs: Job<IXlsFormJobData>[] = [];

    searchString: string;
    filteredFiles: APIFile[];
    isLoading: boolean;
    sort: number;

    constructor(
        private api: DatafiProAPI,
        private alerts: AlertService,
        private actionService: ActionService,
        private element: Element,
        private dialogService: DialogService) {
    }

    activate(params, config) {
        this.sort = -1;
        this.programId = ensureNumber(config.settings.programId);
        this.updateSurveys();
        this.updateFiles();
    }

    async updateSurveys(): Promise<void>  {
        return this.api.programSurveys.find({
            query: {
                program_id: this.programId,
                $limit: 50,
                $sort: { title: 1 },
            },
        }).then((result: Paginated<ProgramSurvey>) => {
            result?.data.forEach((t) => {
                t.template = getReportTemplate('objects', t.surveySchema || []);
            });
            this.surveys = result.data;
        }).catch((e) => {
            this.alerts.create({ label: `Error: ${e.message}`, level: 'error', dismissable: true });
        });
    }

    async toggleSurveyVisibility(survey: ProgramSurvey): Promise<void> {
        return this.api.programSurveys.patch(survey.id, { active: survey.active })
            .then(() => {
                this.alerts.create({
                    label: survey.active ? `${survey.title} Active. Save the program to see changes` : `${survey.title} Inactive. Save the program to see changes`,
                    level: 'success',
                    dismissable: true,
                });
            }).catch((e) => {
                this.alerts.create({ label: `Error: ${e.message}`, level: 'error', dismissable: true });
            });
    }

    async toggleSurveySharing(survey: ProgramSurvey): Promise<void> {
        return this.api.programSurveys.patch(survey.id, { shareToPublic: survey.shareToPublic })
            .then(() => {
                this.alerts.create({
                    label: survey.shareToPublic ? `${survey.title} has been changed to Public` : `${survey.title} has been changed to Private`,
                    level: 'success',
                    dismissable: true,
                });
            }).catch((e) => {
                this.alerts.create({ label: `Error: ${e.message}`, level: 'error', dismissable: true });
            });
    }

    async updateFiles() {
        this.files = [];
        const paths = [];
        for (const name of ['form', 'template', 'geojson']) {
            paths.push(generatePath({ name: name as IServiceType, programId: this.programId }) as string);
        }

        const promises = paths.map(async (path) => {
            return await this.api.files.find({
                query: {
                    $path: path,
                    $limit: 50,
                },
            }).then((result: APIFile[]) => {
                return result.map((file) => {
                    let fileType = '';
                    if (file.id.includes('/forms/')) {
                        fileType = 'Form';
                    } else if (file.id.includes('/templates/')) {
                        fileType = 'Template';
                    } else {
                        fileType = 'Geojson';
                    }
                    return {
                        ...file,
                        path: file.id,
                        name: file.id.split('/').pop(),
                        type: fileType.charAt(0).toUpperCase() + fileType.slice(1),
                    };
                });
            });
        });

        Promise.all(promises).then((results) => {
            this.files = results.reduce((a,b) => a.concat(b));
            this.searchFiles();
        });
    }

    delete(survey: ProgramSurvey) {
        return this.dialogService.open({
            viewModel: MDCDelete,
            model: survey,
        }).whenClosed((result) => {
            if (result.wasCancelled) {
                return;
            }

            this.api.programSurveys
                .remove(survey.id)
                .then(() => {
                    this.alerts.create({ label: 'Survey removed', dismissable: true });
                    this.updateSurveys();
                })
                .catch((e) =>
                    this.alerts.create({
                        label: `Error: ${e.message}`,
                        level: 'error',
                        dismissable: true,
                    }),
                );
        });
    }

    //for file list
    downloadFile(file: APIFile): void {
        const fileParts = file.id.split('/');
        this.actionService.create({
            id: 'SaveFile',
            target: [file],
            name: fileParts[fileParts.length - 1],
        } as SaveFileActionData);
    }

    deleteFile(file: APIFile): Promise<void> {
        return this.dialogService.open({
            viewModel: MDCDelete,
            model: file,
        }).whenClosed((result) => {
            if (result.wasCancelled) {
                return;
            }

            this.api.files.remove(file.id).then(() => {
                this.alerts.create({
                    label: 'File removed',
                    dismissable: true,
                });

                this.element.dispatchEvent(
                    createCustomEvent(
                        'removed',
                        {
                            created: [],
                            removed: [file],
                        } as IUploadEventDetail,
                        true,
                    ),
                );
                this.updateFiles();
            });
        });
    }

    openTemplate(survey) {
        navigator.clipboard.writeText(survey.template)
            .then(() => {
                this.alerts.create({
                    label: 'Form copied',
                    dismissable: true,
                });
            })
            .catch((e) => {
                this.alerts.create({
                    label: `Form could not be copied: ${e}`,
                    dismissable: true,
                });
            });
        return this.dialogService.open({
            viewModel: SurveysListTemplateDialog,
            model: { title: survey.title, template: survey.template },
        });
    }

    addGeoJSON(fileId) {
        return this.dialogService.open({
            viewModel: SurveysListAddGeojson,
            model: {
                file: fileId,
                surveys: this.surveys,
            },
        }).whenClosed(() => {
            return;
        });
    }

    getFileName = (f: File): string => {
        const fileType = this.getFileType(f.name) as IServiceType;
        return generatePath({
            name: fileType,
            programId: this.programId,
            fileName: f.name,
        }) as string;
    }

    addFileToList = (eventDetail: IUploadEventDetail) => {
        eventDetail.created.forEach((item) => {
            if (this.getFileType(item.id) === 'form') {
                this.publishForm(item);
            }
        });
        this.updateFiles();

    }

    publishForm(item: APIFile) {
        return this.api.jobs.create({
            name: 'xform',
            data: {
                fileId: item.id,
            },
        } as Job<IXlsFormJobData>).then((job: Job<IXlsFormJobData>) => {
            this.jobs.push(job);
            return getJobResult(this.api.jobs, job)
                .then((data) => {
                    this.alerts.create({
                        label: 'Form published successfully',
                        level: 'success',
                        dismissable: true,
                    });
                })
                .catch((e) => {
                    this.alerts.create({
                        label: `Form failed to publish: ${e.message}`,
                        level: 'error',
                        timeout: 10000,
                        dismissable: true,
                    });
                });
        });
    }

    getFileType = (fileName: string): string => {
        const fileExt = fileName.split('.').pop();
        const libreOfficeRegex = /(odt|ods|odp|odg|odf|sxw|sxi|sxc|sxd|stw|stx|sxg|sxm|sxb)$/i;

        if (fileExt === 'xlsx' || fileExt === 'xls' || fileExt === 'xml') {
            return 'form';
        }

        if (fileExt === 'geojson' || fileExt === 'json') {
            return 'geojson';
        }

        if (libreOfficeRegex.test(fileName)) {
            return 'template';
        }

        return 'other';
    }

    @debounced(200)
    searchFiles() {
        this.filteredFiles = [];

        if (this.searchString?.length > 1) {
            this.filteredFiles = this.files.filter((f) => f.name.toLowerCase().includes(this.searchString.toLowerCase()));
        } else {
            this.filteredFiles = this.files;
        }
        return this.filteredFiles;
    }

    handleSort(): void {
        this.sort === 1 ? this.sort = -1 : this.sort = 1;

        this.filteredFiles.sort(
            (a: { name: string }, b: { name: string }) => {
                const nameA = (a.name || '').toUpperCase();
                const nameB = (b.name || '').toUpperCase();

                if (this.sort === 1) {
                    // asc, arrow up
                    if (nameA < nameB) { return -1; }
                    if (nameA > nameB) { return 1; }
                    return 0;
                } else {
                    // desc, arrow down
                    if (nameA > nameB) { return -1; }
                    if (nameA < nameB) { return 1; }
                    return 0;
                }
            },
        );
    }
}
