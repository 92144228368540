import { autoinject } from 'aurelia-framework';
import { Program } from '../../../types/Program';
import { debounced } from '../../../util/decorators/debounced';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';
import { Router } from 'aurelia-router';
import { recursiveQuery } from '../../../util/query/recursive';
import { Paginated, Params } from '@feathersjs/feathers';
import { ValidatedRole } from '../../../types/Roles';
import { ValidatedUser } from '../../../types/Users';
import { AlertService } from '../../../services/util/Alert';
import { ValidatedOrganization } from '../../../types/Organizations';

export interface ColumnList {
    name: string;
    active: boolean;
    id: number;
}
@autoinject
export class UsersEdit {
    sort: number;
    programId: number;
    program: Program;
    users: ValidatedUser[];
    usersParams: Params = {
        $limit: 1000,
    };
    usersLoading: boolean;
    addedToProgram: ValidatedUser[];
    appRoles: ValidatedRole[];
    addedToProgramRole: ValidatedRole[];

    filteredUsers;
    mergedProgramUserRole;
    searchString: string;
    columnList = [
        {
            name: 'Role',
            active: false,
            id: undefined,
        },
        {
            name: 'Organizations',
            active: false,
            id: undefined,
        },
    ] as ColumnList[];

    orgs: ValidatedOrganization[];

    constructor(
        private api: DatafiProAPI,
        private router: Router,
        private alerts: AlertService,
    ) {}

    async activate(params: any) {
        this.sort = -1;
        this.programId = parseInt(params.id, 10);
        this.orgs = await this.api.organizations.find({query: {$limit: 50}}).then((orgs: ValidatedOrganization) => orgs.data);

        this.program = this.programId
            ? await this.api.programs
                .get(this.programId, {
                    query: {
                        $eager: '[assetTypes, configs, surveys, roles.role]',
                    },
                })
                .then((result) => new Program(result, this.api.files))
                .catch((e) => undefined)
            : new Program({}, this.api.files);

        if (!this.program) {
            this.router.navigateToRoute('home');
        }

        await this.refresh();
        this.handleSort();
    }

    handleSort(): void {
        this.sort === 1 ? this.sort = -1 : this.sort = 1;

        if (!this.filteredUsers) {
            this.filteredUsers = this.mergedProgramUserRole;
        }

        this.filteredUsers.sort(
            (a: { fullname?: string }, b: { fullname: string }) => {
                const nameA = (a.fullname || '').toUpperCase();
                const nameB = (b.fullname || '').toUpperCase();

                if (this.sort === 1) {
                    // asc, arrow up
                    return nameA < nameB ? -1 : 1;
                } else {
                    // desc, arrow down
                    return nameA > nameB ? -1 : 1;
                }
            },
        );
    }

    async refresh(): Promise<void> {
        return this.updateProgram().then(() => {
            return Promise.all([
                this.updateUsers(),
                this.updateAppRoles(),
            ]).then(() => {
                this.updateUserTypes();
            });
        });
    }

    async updateUsers(): Promise<void> {
        this.usersLoading = true;
        return recursiveQuery(
            this.api.users,
            {
                ...this.usersParams,
                $limit: 50,
            },
            [],
        )
            .then((result: any) => {
                this.users = result.map((u) => new ValidatedUser(u, this.api.files));
            })
            .finally(() => (this.usersLoading = false));
    }

    async updateAppRoles(): Promise<void> {
        // get role ID's for program roles
        const appRoles = (await this.api.roles.find({
            query: {
                scopes: { $contains: '["program"]' },
            },
        })) as Paginated<ValidatedRole>;
        this.appRoles = appRoles.data;
    }

    updateUserRole(role_name, user): void {
        const oldRole = user.role.role.role_name;
        const newRole = this.appRoles.find((r) => r.role_name === role_name);

        // Find id of new role
        const roleToUpdate = this.program.roles.find((r) => {
            return r.id === user.role.id;
        });

        this.api.programUsersRoles.patch(roleToUpdate.id, { role_id: newRole.id })
            .then(() => {
                this.api.programs.patch(this.program.id, this.program);
                this.refresh();
                this.filterUsers();
                this.alerts.create({
                    label: 'User role changed.',
                    level: 'success',
                    dismissable: true,
                });
            })
            .catch((e) => {
                user.role.role.role_name = oldRole;
                this.alerts.create({
                    label: e.message,
                    level: 'error',
                    dismissable: true,
                });
            });

    }

    @debounced(100)
    async updateProgram(): Promise<void> {
        if (this.program) {
            this.program.destroy();
        }
        this.program = this.programId
            ? await this.api.programs
                .get(this.programId, {
                    query: {
                        $eager: '[assetTypes, configs, surveys, roles.role]',
                    },
                })
                .then((result) => new Program(result, this.api.files))
                .catch((e) => undefined)
            : new Program({}, this.api.files);

        if (!this.program) {
            this.router.navigateToRoute('home');
        }
    }

    createRole(user: ValidatedUser, role: string): Promise<void> {
        const roleToAdd = this.appRoles.find((r) => r.role_name === role);
        const existingRole = this.program.roles.find((r) => {
            return r.role_id === roleToAdd.id && r.user_id === user.id;
        });

        if (existingRole) {
            return this.alerts.create({
                label: 'User exists in table',
                level: 'warning',
                dismissable: true,
            });
        }

        return this.api.programUsersRoles
            .create({
                role_id: roleToAdd.id,
                user_id: user.id,
                program_id: this.program.id,
            })
            .then(() => {
                this.api.programs.patch(this.program.id, this.program);
                this.refresh();
                this.filterUsers();

                this.alerts.create({
                    label: 'Role added.',
                    level: 'success',
                    dismissable: true,
                });
            })
            .catch((e) =>
                this.alerts.create({
                    label: e.message,
                    level: 'error',
                    dismissable: true,
                }),
            );
    }

    async removeRole(userId: number): Promise<void> {
        const roleToRemove = this.program.roles.find((r) => {
            return r.user_id === userId;
        });

        return this.api.programUsersRoles
            .remove(roleToRemove.id)
            .then(() => {
                this.program.roles = this.program.roles.filter((r) => {
                    return r.id !== roleToRemove.id;
                });
                this.mergedProgramUserRole = this.mergedProgramUserRole.filter(
                    (allUsers) => {
                        return allUsers.id !== userId;
                    },
                );
                this.filterUsers();
                this.alerts.create({
                    label: 'User removed. Save the Program to persist changes',
                    level: 'success',
                    dismissable: true,
                });
            })
            .catch((e) =>
                this.alerts.create({
                    label: e.message,
                    level: 'error',
                    dismissable: true,
                }),
            );
    }

    async updateUserTypes(): Promise<void> {
        if (this.program) {
            this.addedToProgram = this.users.filter((user) => {
                return !!this.program.roles.find(
                    (role) => role.user_id === user.id,
                );
            });

            this.addedToProgramRole = this.program.roles.filter((role) => {
                return !!this.addedToProgram.find(
                    (user) => role.user_id === user.id,
                );
            }) as ValidatedRole[];

            this.mergedProgramUserRole = this.addedToProgram.map((user) => {
                const orgName = this.orgs.find((org) => org.id === user?.organizationId);
                const role = this.addedToProgramRole.find((r) => r.user_id === user.id);
                return { ...user, role, orgName: orgName?.name };
            });
            this.filterUsers();
        }
    }

    filterUsersByColumn(activeColumns: ColumnList[]) {
        const org = activeColumns.find((col) => col.name === 'Organizations');
        const role = activeColumns.find((col) => col.name === 'Role');
        let orgFilter = [];

        if (activeColumns.length === 0) { // No column active
            this.filteredUsers = this.mergedProgramUserRole;
        } else if (activeColumns.length === 1) { // One column active
            this.filteredUsers = this.mergedProgramUserRole.filter((u) => org ?
                u.organizationId === org.id : u.role.role.id === role.id);
        } else { // Two columns active
            orgFilter = this.mergedProgramUserRole.filter((u) => u.organizationId === org.id);
            if (orgFilter.length) {
                this.filteredUsers = orgFilter.filter((u) => u.role.role.id === role.id);
            } else {
                this.filteredUsers = this.mergedProgramUserRole.filter((u) => u.role.role.id === role.id);
            }
        }
    }

    @debounced(200)
    filterUsers(filterId?: number, selection?: ColumnList) {
        if (selection) {
            this.columnList.forEach((col) => {
                if (col.name === selection.name) {
                    col.id = filterId || filterId === 0 ? filterId : undefined;
                    col.active = filterId || filterId === 0 ? true : false;
                }
            });
        }
        // Filter Users by any active Columns
        const activeColumns = this.columnList.filter((option) => option.active);
        this.filterUsersByColumn(activeColumns);

        // Filter Users by search box
        if (this.searchString?.length) {
            this.filteredUsers = this.filteredUsers.filter((u) => u.fullname.toLowerCase().includes(this.searchString.toLowerCase()));
        }
    }
}
