import { Job } from '@wsb_dev/datafi-shared/lib/types';
import { autoinject } from 'aurelia-dependency-injection';
import { NavigationInstruction, Router } from 'aurelia-router';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';

@autoinject
export class JobDetail {
    jobId: number;
    webhookId: number;
    programId: number;
    job: Job<any>;
    loading: boolean;
    timer: ReturnType<typeof setInterval>;

    constructor(private api: DatafiProAPI, private router: Router) { }

    activate(params, config) {
        this.webhookId = params.id;
        this.jobId = params.jobId;
        this.programId = config.settings.programId;

        this.updateJob();
    }

    detached(){
        this.clearTimer();
    }

    async updateJob(retryTimeout = 4000) {
        this.loading = true;
        return this.api.jobs.get(this.jobId, { query: { program_id: this.programId, $select: ['id', 'status', 'error', 'result', 'data'] } }).then((result) => {
            this.job = result;

            // if job is still pending, re-fetch the job data
            if(!['completed', 'failed'].includes(result.status)){
                this.scheduleTimer(retryTimeout);
            } else {
                this.clearTimer();
            }

        })
            .finally(() => this.loading = false);
    }

    scheduleTimer(retryTimeout){
        if(this.timer){
            return;
        }
        this.timer = setInterval(() => this.updateJob(), retryTimeout);
    }

    clearTimer(){
        if(this.timer){
            clearInterval(this.timer);
            this.timer = undefined;
        }
    }
}
